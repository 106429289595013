class FilterPanel extends dk.Panel {

    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }

    construct_panel_body(body) {
        const self = this;
        const filterbox = $('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                month: {
                    label: 'Måned <small>åååå-mm</small>',
                    // widget: dk.forms.MonthWidget,
                    value: undefined,
                    construct_filter: function (location, filter) {
                        const inputgrp = $('<div/>').addClass('input-group');
                        const mnth = $('<input/>', {
                            type: 'text',
                            placeholder: 'åååå-mm'
                        }).addClass('form-control month-input');
                        const nxt = $('<span>&rsaquo;</span>').addClass('input-group-addon');
                        inputgrp.append(mnth).append(nxt);
                        this.widget = location.append(inputgrp);

                        $(this.widget).find('input.month-input').on('change', function () {
                            filter.value = $(this).val();
                            dk.trigger(filter, 'change', filter);
                        });
                    }
                },
                missing_photo: {
                    label: 'Mangler bilde',
                    values: {
                        true: 'Ja',
                        false: 'Nei'
                    }
                },
                status: {
                    label: 'Gjennomføring',
                    select_multiple: true,
                    values: {
                        // Må holdes ajour med switch statement i progress i ProgressResults
                        'logged-in': 'Innlogget',
                        'sent-to-test': 'Startet',
                        'finished-pass': 'Godkjent',
                        'finished-fail': 'Underkjent',
                        'error': 'Error'
                        // 'not-started': 'Not Started',
                        // 'running': 'Running',
                        // 'paused': 'Paused',
                        // 'finished': 'Ferdig',
                    }
                },
                bfp: {
                    label: 'Bestått båtførerprøven',
                    values: {
                        true: 'Ja',
                        false: 'Nei'
                    }
                },
                testleader: {
                    label: 'Testleder',
                    select_multiple: true
                }
            }
        });
        return body;
    }
}


export const ProgressResults = function (id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.Data.create({
            pagesize: 15,
            datasource: dk.data.AjaxDataSource.create({
                url: datasource_url
            })
        }),
        construct_filter: function (location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        },
        construct_table: function (location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                download: downloadwidget,
                table_data: ds,
                columns: {
                    photo: {label: 'Foto'},
                    bfp: {
                        label: 'Status',
                        format: function (val) {
                            switch (val) {
                                case true:
                                    return `<div title="Bestått" style="background-color: #00ae00;  border-radius: 50%; height: 25px; width: 25px; "></div>`;
                                case false:
                                    return `<div title="Ikke bestått" style="background-color: #ff1e1e; border-radius: 50%; height: 25px; width: 25px;"></div>`;
                                default:
                                    return `<div title="Ukjent" style="background-color: #999; border-radius: 50%; height: 25px; width: 25px;"></div>`;
                            }
                        }
                    },
                    testleader: {label: 'Testleder'},
                    candidate: {
                        label: 'Kandidat',
                        format: function (val, rec) {
                            return `<a href="../candidate/${rec.candidateid}/">${val}</a>`
                        }
                    },
                    email: {label: 'E-post'},
                    candidate_name: {label: 'Navn'},
                    progress: {
                        label: 'Gjennomføring',
                        format: function (val, rec) {
                            let status;
                            switch (val) {
                                case 'finished-fail':
                                    status = 'Underkjent';
                                    break;
                                case 'finished-pass':
                                    status = 'Godkjent';
                                    break;
                                case 'logged-in':
                                    status = 'Innlogget';
                                    break;
                                case 'sent-to-test':
                                    status = 'Startet';
                                    break;
                                case 'error':
                                    status = 'Error';
                                    break;
                                default:
                                    status = val;
                                    break;
                            }
                            return `${rec.start.toString()} <br>
                                    Gjennomføring: ${status} <br>
                                    <span>Resultat: ${rec.result ? 'Bestått' : 'Ikke bestått'}</span>`;
                        }
                    },
                }
            });
        }
    });
};
dk.ready(() => {
    $('#progress .excelbtn').on('click', function () {
        const filename = "candidates";
        //noinspection JSUnresolvedVariable
        $$.progress.table.table_data.fetch_csv(filename);
    });
});
