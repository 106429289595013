class FilterPanel extends dk.Panel {

    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }

    construct_panel_body(body) {
        const self = this;
        const filterbox = $('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                active: {
                    label: 'Aktiv',
                    values: {
                        true: 'Ja',
                        false: 'Nei'
                    }
                },
            }
        });
        return body;
    }
}



export const TestleadersResults = function (id, datasource_url, is_staff) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.Data.create({
            pagesize: 100,
            datasource: dk.data.AjaxDataSource.create({
                url: datasource_url
            })
        }),
        construct_filter: function (location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        },

        construct_table: function (location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                download: downloadwidget,
                table_data: ds,
                columns: {
                    username: {
                        label: 'Brukernavn',
                        format: function (val, rec) {
                            if (is_staff) {
                                return `<a href="/testleder/${rec.userid}/edit/">${val}</a>`;
                            } else {
                                return val;
                            }
                        }
                    },
                    first_name: {label: 'Fornavn'},
                    last_name: {label: 'Etternavn'},
                    email: {label: 'E-post'},
                    passed_tl: {
                        label: 'Bestått testledertest',
                        format: dk.format.bool('Ja', 'Nei')
                    },
                    active: {
                        label: 'Aktiv',
                        format: dk.format.bool('Ja', 'Nei')
                    }
                }
            });
        }
    });
};
dk.ready(() => {
    const tlsect = $('#testleaders-section');
    tlsect.find('.excelbtn').hide();
    tlsect.find('.add-button').replaceWith('<a style="margin-right: 5px;" class="btn btn-success btn-sm" href="activate-tl/"><dk-icon style="margin-right: 5px;" value="plus:2x"></dk-icon>Ny testleder</a>');
});
