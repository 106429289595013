import {BoolToggleWidget} from "./toggle-widget";
import {ProgressResults} from "./progress-datasource";
// import {MapOrders} from "./maporders-datasource";
import {TestleadersResults} from "./testleaders-datasource";

const bfpregistration = {
    // insert exported names here
    BoolToggleWidget,
    ProgressResults,
    // MapOrders,
    TestleadersResults,
    foo:42,
    bar:43,
    baz: 43,
};

const foo = 'bar';
export default bfpregistration;
