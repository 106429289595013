const trueval = {value: true, icon: 'https://static.datakortet.no/bfpregistration/ikn/bp3-yes.svg', color: '#2aa52a'};
const falseval = {value: false, icon: 'https://static.datakortet.no/bfpregistration/ikn/bp3-no.svg', color: '#fc4413'};


/**
 *  The BoolToggleWidget is a compound widget containing the following elements (asciiflow.com):
 *
 *     .title            .explanation
 *      +-------------+   +-----------------+
 *      |             |   |   tag           |   title/name of widget
 *      |             |   +-----------------+
 *      |   icon2     |   +-----------------+
 *      |             |   |   txt           |   description of widget
 *      |             |   +-----------------+
 *      |             |   +-----------------+
 *      +-------------+   |   msg           |
 *                        +-----------------+
 *                        +-----------------+
 *                        |   icon          |   yes/no toggle widget
 *                        +-----------------+
 *                        +-----------------+
 *                        |   feedback      |   messages from server
 *                        +-----------------+
 *
 *  "boxes" can be filled from the html:
 *
 *     <div id="foo-widget">
 *         <div set=explanation.msg when=true>some text for when value is true</div>
 *         <div set=explanation.msg when=false>similarly for when value is false</div>
 *     </div>
 *     <script>BoolToggleWidget.create_on('#foo-widget', {...});</script>
 *
 *  The `set` attribute indicates the path to the box where the div should be appended.
 *  The (optional) `when` attribute indicates for which value the div should be visible.
 *
 *  The flow is:
 *
 *    1. display current value
 *    2. user clicks on yes/no widget
 *    3. (optional) if a .first_get_action_data() is defined then call it.
 *    4. the first_get_action_data should return a boolean indicating wether to proceed
 *       with the next step (true => proceed, false => stop here).
 *        * it is expected that the method will assign a value to `this.action_data`...
 *        * .. OR.. initiate an action (popup) that will call set_action_data(data)
 *          (NOTE: assign directly to this.action_data in method, but call set_action_data()
 *           from a callback)
 *    5. (if we've not been cancelled) proceed to make a dk.json() call to the datasource's
 *       !toggle endpoint, sending our version of the value, and also any action_data that
 *       has been collected/set.
 *    6. see datakortet/bfpregistration/profile/datasources.py for a description of the server
 *       actions.
 *    7. when we get a message back from the server it is tagged (.type) as either
 *         ok:      everything is ok, message.value contains the new value
 *         error:   something went wrong, message.value contains a description of what
 *         update:  we tried to set a value that was already set on the server
 *                  (open for future expansion to websocket'ish asynchronous updates)
 *    8. we set the new value, display the message, and and emit an update signal, before
 *       calling the "after" hooks.
 *
 *       => to update another piece of the UI after a new value has been set, either
 *          (a) define one of the after methods and perform the update, or
 *          (b) handle the update event, e.g. (re-)fetching a <dk-load..> tag.
 *
 *              my_bool_toggle.on('update', (newval) => {
 *                  dk('#my-dk-load-tag').fetch();
 *              });
 *
 *
 */
export class BoolToggleWidget extends dk.Widget {
    constructor(...args) {
        super({
            tag: '',                    // the "name" (title) of the widget (e.g. "DUPLIKAT")
            label: '',                  // the text of the widget (e.g. "Kan KA ...?")
            tag_icon: '',               // the icon representing the widget
            value: undefined,           // the value must be one of the keys in .values
            values: {
                [true]: trueval,        // true/false are not allowed as keys unless you use this syntax
                [false]: falseval
            },
            // the icon to use if the current value doesn't define an icon
            unknown_icon: 'https://static.datakortet.no/bfpregistration/ikn/bp-unknown.svg',

            // the .url attribute has a special meaning in dk.Widget (it fetches initial data), so use the
            // name 'actionurl'. It must point to a datasource that implements !toggle
            actionurl: null,

            structure: {
                css: {display: 'flex', justifyContent: 'space-between', alignItems: 'top'},
                title: {
                    css: {width: 65, margin: '0 10px 10px 1px', textAlign: 'center', xoutline: '1px solid red'},
                    icon2: {
                        // this is the icon representing the semantic of the widget (ie. photo vs dupl. permissions)
                        // css: { width: 65 },
                        css: {height: 65, maxWidth: 65},
                        template: 'img'
                    }
                },

                explanation: {
                    css: {flex: 1, xoutline: '1px solid red', margin: 1},
                    tag: {
                        // the (UI) name of the widget (this.tag)
                        css: {textTransform: 'uppercase', fontSize: 10}
                    },
                    txt: {},

                    icon: {     // this is the yes/no icon
                        template: 'img',
                        css: {width: 65, cursor: 'pointer', xmargin: 10, xoutline: '1px solid red'}
                        // css: { width: 45, cursor: 'pointer', xmargin: 10 , xoutline: '1px solid red'}
                    },
                    msg: {},

                    feedback: {}  // messages from the server are flashed in the feedback box
                }
            }
        }, ...args);
    }

    construct() {
        let self = this;
        this.icon = this.explanation.icon;      // shortcut
        this.__timer = null;                    // the timer for the flashing of the message from the server
        this.widget('[set]').each(function () { // implement the `set` attribute functionality
            let $this = $(this);
            dk.traverse(self, $this.attr('set')).append($this);
        });
        if (this.value !== undefined) {
            this._when_value(this.value);
        } else {
            // setTimeout(() => this.call_value(), 0);
            this.call_value();
        }
    }


    _when_value(v) {    // implement the `when` attribute functionality
        this.widget(`[when=${!v}]`).hide();
        this.widget(`[when=${v}]`).show();
    }


    set_value(v) {      // setting a new value needs to re-draw the widget
        this.value = v;
        this._when_value(v);
        this.draw();
    }


    draw(val) {
        if (val === null) val = this.value;         // do we have a value..?
        if (val === undefined) val = this.value;    // any value..?
        if (val === undefined) return;

        let curval = this.values[val];
        this.explanation.txt.html(curval.label || this.label);
        this.icon.attr('src', curval.icon || this.unknown_icon);
        this.explanation.tag.html(this.tag);
        this.title.icon2.prop('src', this.tag_icon);
    }


    /**
     * Display a message from the server
     * @param type  ok|error|update
     * @param msg   message text
     */
    message(type, msg) {
        if (this.__timer !== null) clearTimeout(this.__timer);
        if (type !== 'ok') {
            msg = type + ': ' + msg;
            dk.warn(msg);
        } else {
            msg = type + ': ' + msg;
            dk.log(msg);
        }
        this.explanation.feedback.text(msg);
        this.explanation.feedback.css(
            type === 'error' ? {
                margin: 10, backgroundColor: 'red', color: 'white'
            } : {
                margin: 10, backgroundColor: 'yellow', color: 'black'
            }
        );
        this.__timer = setTimeout(() => this.explanation.feedback.text(''), 4000);
    }


    // hooks that can be overridden to run code after we get a message back from the server
    after_true() {
    }


    after_false() {
    }


    after() {
    }


    // calling the hooks
    _after(val) {
        this.trigger('update', this.value);
        this.after();
        if (val) {
            this.after_true();
        } else {
            this.after_false();
        }
    }


    set_action_data(data) {
        this.action_data = data;
        this.icon.click();
    }


    _handle_toggle_message(response, message, data, uimessage = false) {
        dk.debug('handle-toggle-message:', response, message, data);
        let self = this;

        switch (response.status.code) {
            case 500:
                // server error (always display these!)
                self.message('error', response.status.message);
                break;

            case 201:  // update
                // the server wants to update our .value without any transition having
                // taken place at the server.
                self.set_value(response.result.value);
                if (uimessage) self.message('outdated', response.result.value);
                self._after(response.result.value);
                break;

            case 200: // ok
                self.set_value(response.result.value);
                if (uimessage) self.message('ok', 'verdien er endret');
                self._after(response.result.value);
        }
        if (response.updates) {
            Object.keys(response.updates).forEach(path => {
                dk.traverse(self, path).html(response.updates[path]);
            })
        }
    }


    _call_datasource(message, data, uimessage) {
        let self = this;
        dk.debug("calling datasource:", this.actionurl, message, "data:", data, "uimessage:", uimessage);
        let args = {
            url: self.actionurl + message,
            beforeSend: () => self.start_busy(),
            success(response) {
                self.end_busy();
                // dk.debug("response:", response);  // _handle does the debug print (in context)
                self._handle_toggle_message(response, message, data, uimessage);
            }
        };
        if (data) args.data = data;
        dk.json(args);
    }


    call_value() {
        this._call_datasource('!value', {}, false);
    }


    call_toggle() {
        let data = {val: this.value, data: this.action_data || null};
        this._call_datasource('!toggle', data, true);
    }


    handlers() {
        let self = this;

        this.icon.on('click dblclick', function (e) {
            e.stopPropagation();

            // if we have a method to get data, but no data, then fetch(?) data first..
            if (self.first_get_action_data && self.action_data === undefined) {
                console.log("calling first get action data");
                if (self.first_get_action_data()) self.call_toggle();
            } else {
                self.call_toggle();
            }
        });

    }
}
